<template>
  <nav>
    <ul>
      <li v-for="item in menu" :key="item.path">
        <router-link
          active-class="active"
          :to="item.path"
          tag="a"
          :exact="item.path === '/'"
          >{{ $t(item.title) }}</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import NavigationMenu from "@/config/navigation";
export default {
  data() {
    return {
      menu: NavigationMenu,
    };
  },
};
</script>

<style lang="scss">
nav {
  background-color: $main-color;
  height: 35px;

  ul {
    display: inline-flex;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    li {
      height: 100%;
      border-left: 2px solid $constrast-color;
      transition: 0.1s all ease-in-out;
      display: flex;
      &:first-child {
        border-left: none;
      }
      &:hover {
        background-color: $constrast-color;
        a {
          color: $main-color;
        }
      }
    }
    a {
      align-self: center;
      padding: 10px 50px;
      text-decoration: none;
      text-transform: uppercase;
      color: $constrast-color;
      font-weight: bold;
      font-size: 13px;
    }
  }
}

</style>
