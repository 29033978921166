<template>
  <footer>
    <div class="footer__navigation">
      <ul v-for="(item, index) in menu" :key="index">
        <h3>{{ $t(item.title) }}</h3>
        <li v-for="(child, j) in item.children" :key="j">
          <a :href="child.path" target="_blank">{{ $t(child.title) }}</a>
        </li>
      </ul>
    </div>
    <div class="footer__information">
      <ul>
        <li>
          {{ $t("footer.title") }}
        </li>
        <li>
          {{ $t("footer.address") }}
        </li>
        <li>
          {{ $t("footer.phone") }}:
          <a href="tel:02923734713">0292 3 734713</a> -
          <a href="tel:02923831301">0292 3 831301</a>; Fax: 84 0292 3830841;
          {{ $t("footer.email") }}:
          <a href="mailto:office@cit.ctu.edu.vn">office@cit.ctu.edu.vn</a>
        </li>
        <br />
        <li>
          {{ $t("footer.tmthai") }} {{ $t("footer.email") }}:
          <a href="mailto:tmthai@cit.ctu.edu.vn"> tmthai@cit.ctu.edu.vn</a>
        </li>
        <li>
          {{ $t("footer.vhtram") }} {{ $t("footer.email") }}:
          <a href="mailto:vhtram@cit.ctu.edu.vn"> vhtram@cit.ctu.edu.vn</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import FooterMenu from "@/config/footer";
export default {
  data() {
    return {
      menu: FooterMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: $main-color;
  min-height: 500px;
  max-height: 600px;
  padding-top: 40px;
  color: $constrast-color;
  position: relative;
}
ul {
  list-style-type: none;
  text-align: left;
}
.footer {
  &__navigation {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
    ul {
      list-style-type: none;
      text-transform: uppercase;
      h3 {
        font-size: 20px;
        margin-bottom: 30px;
      }
      li {
        padding: 8px;
        transition: 0.3s all ease;
        cursor: pointer;
        &:hover {
          background-color: $constrast-color;
          a {
            color: $main-color;
          }
        }
      }
      a {
        font-size: 14px;
        color: $constrast-color;
        text-decoration: none;
      }
    }
  }
  &__information {
    position: absolute;
    bottom: 50px;
    left: 60px;
    li {
      margin: 8px auto;
    }
    a {
      text-decoration: none;
      color: $constrast-color;
    }
  }
}
</style>
