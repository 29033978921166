<template>
  <div>
    <go-top :size="50" alt="backtotop" :has-outline="false"></go-top>
    <Header></Header>
    <Menu></Menu>
    <slot />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import GoTop from "@inotom/vue-go-top";

export default {
  components: {
    Header,
    Menu,
    Footer,
    GoTop,
  },
};
</script>

<style></style>
