<template>
  <header>
    <div class="introduction">
      <img id="logo" src="~@/assets/icons/logo.svg" alt="logo" />
      <h1>
        {{ $t("header.h1") }}<br />
        {{ $t("header.sub") }}
      </h1>
      <div id="localization">
        <p>{{ $t("header.nn") }}</p>
        <img
          :class="['flag', { disable: $i18n.locale === 'en' }]"
          src="~@/assets/icons/vi.png"
          alt="vi"
          @click="changeLanguage('vi')"
        />
        <img
          :class="['flag', { disable: $i18n.locale === 'vi' }]"
          src="~@/assets/icons/en.png"
          alt="en"
          @click="changeLanguage('en')"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isDisable: true,
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.disable {
  filter: grayscale(90%);
}
.introduction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 50px;
  background-color: white;
  text-transform: uppercase;
  #logo {
    width: 80px;
  }
  h1 {
    margin-left: 30px;
    justify-self: left;
    flex-grow: 2;
    font-size: 24px;
    text-align: left;
  }
  #localization {
    align-self: flex-start;
    text-align: left;
    p {
      font-size: 13px;
      text-transform: initial;
    }
  }
}
.flag {
  cursor: pointer;
  width: 25px;
  margin-right: 10px;
}
</style>
