export default [
  {
    title: "footer.system.base",
    children: [
      {
        title: "footer.system.cit",
        path: "http://cit.ctu.edu.vn/",
      },
      {
        title: "footer.system.elcit",
        path: "https://elcit.ctu.edu.vn/",
      },
      {
        title: "footer.system.htql",
        path: "https://htql.ctu.edu.vn/",
      },
      {
        title: "footer.system.xnd",
        path: "http://cit.ctu.edu.vn/vpk/login",
      },
    ],
  },
  {
    title: "footer.doc.base",
    children: [
      {
        title: "footer.doc.central",
        path: "https://www.ctu.edu.vn/van-ban.html#a-v-n-ba-n-ca-p-trung-ng",
      },
      {
        title: "footer.doc.ministry",
        path: "https://www.ctu.edu.vn/van-ban.html#a-v-n-ba-n-ca-p-ba",
      },
      {
        title: "footer.doc.uni",
        path: "https://www.ctu.edu.vn/van-ban.html#a-v-n-ba-n-ca-p-tr-a-ng",
      },
    ],
  },
  {
    title: "footer.link.base",
    children: [
      {
        title: "footer.link.personnel",
        path: "https://dp.ctu.edu.vn/",
      },
      {
        title: "footer.link.academic",
        path: "https://daa.ctu.edu.vn/",
      },
      {
        title: "footer.link.student",
        path: "https://dsa.ctu.edu.vn/",
      },
      {
        title: "footer.link.research",
        path: "https://dra.ctu.edu.vn/",
      },
    ],
  },
  {
    title: "footer.union.base",
    children: [
      {
        title: "footer.union.partycommittee",
        path: "http://cpv.ctu.edu.vn/",
      },
      {
        title: "footer.union.trade",
        path: "http://tu.ctu.edu.vn/",
      },
      {
        title: "footer.union.uni",
        path: "http://yu.ctu.edu.vn/",
      },
      {
        title: "footer.union.college",
        path: "http://cit.ctu.edu.vn/doankhoa/",
      },
    ],
  },
];
