export default [
  {
    title: "menu.home",
    path: "/",
  },
  {
    title: "menu.intro",
    path: "/introduction",
  },
  {
    title: "menu.news",
    path: "/news",
  },
  {
    title: "menu.lecturer",
    path: "/lecturer",
  },
  {
    title: "menu.education",
    path: "/academic",
  },
  {
    title: "menu.research",
    path: "/research",
  },
  {
    title: "menu.alumni",
    path: "/alumni",
  },
];
